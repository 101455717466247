import { Grid, Box } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle'
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { useLocation, Link } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles } from '@material-ui/core';
import product_data from "../data/product_catgory.json";
import cogoToast from "cogo-toast";
import axios from "axios";
import CardHeader from '@material-ui/core/CardHeader';
import SignupModal from './modals/SignUpModal';
import { CircularProgress } from '@material-ui/core';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        textAlign: 'justify',
        padding: '5%'
    },
    img: {
        width: '20%',
        padding: '7%',
    },
    textStyle: {
        textAlign: "justify",
        padding: '3%',
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#1a237e',
        color: 'white'
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    media: {
        height: '100%',
        // borderRadius: '50%',
        // boxShadow: '0 0 1px 0 rgb(0 0 0 / 31 %), 0 3px 4px - 2px rgb(0 0 0 / 25 %)',
        // border: '8px solid #959ca0',
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        padding: 'calc(1vw + 1vh)',
        width: 'calc(10vw + 7vh)',
        height: 'calc(10vw + 7vh)',
        boxShadow: 'none',
        "&:hover, &:focus": {
            border: '1px solid #c8c8c8'
        },

    },
    modalButton: {
        border: '2px solid #1a237e',
        color: '#1a237e',
        fontSize: 'calc(0.5vw + 0.7vh)',
        // marginRight: 'calc(0.45vw + 0.3vh)',
        paddingLeft: 'calc(1vw + 1vh)',
        paddingRight: 'calc(1vw + 1vh)',
        borderRadius: '25px',
        fontWeight: 600,
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#1a237e',
            color: 'white'
        },
    },
    overlay: {
        position: 'absolute',
        top: '0%',
        // left: '20px',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        color: 'white',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(154,221,251,0.6)'
        },
    },
    productName: {
        position: 'absolute',
        backgroundColor: 'rgb(41, 182, 246)',
        color: 'white',
        top: '45%',
        width: '100%',
    }
}));

export default function Pricing(props) {
    const classes = useStyles();
    const [loading, isLoading] = useState(false);
    const [category, setData] = useState();
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

    useEffect(() => {
        axios({
            url: `${process.env.REACT_APP_BASE_API}/Api/product_category`,
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        })
            .then(res => {
                if (res.data.status === "success" || res.data.status === true) {
                    setData(res.data.data)
                    isLoading(true)
                }
            })
            .catch(error => {
                // console.log("in error", error.response.data.password);
                // cogoToast.error(error.response.data.message)
            })
    }, []);

    return (
        <Box className={classes.paperContainer}>
            {console.log(lastItem)}
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                {loading == true ? <>
                <Grid xs={12}>
                    {/* <ScrollAnimation animateIn='fadeInUp'> */}
                    <MainTitle paddingTop='calc(8vh)' color='#1a237e' value='Product Categories' />
                    {/* </ScrollAnimation> */}

                </Grid>
                
                {lastItem != "product_categories" ?
                    (category ? category.slice(1, 5)
                        .map(o => {
                            return (
                                <>
                                    <Grid xs={10} sm={8} md={2} >
                                        <center>
                                            <ScrollAnimation animateIn='fadeInUp'>
                                                <Link
                                                    to={{
                                                        pathname: "/product_list",
                                                        id: o.id,
                                                        hash: "#" + o.id,
                                                    }}
                                                    >
                                                    <Card className={classes.card}>
                                                        <CardMedia image={o.image_path} className={classes.media} />
                                                        {/* <div className={classes.overlay}>

                                            </div> */}
                                                    </Card>
                                                    {/* <div className={classes.productName}> */}
                                                    <p style={{ marginTop: 'calc(1vw + 1vh)', marginBottom: 'calc(1vw + 1vh)', fontSize: 'calc(0.6vw + 1vh)' }}>{o.name}</p>
                                                    {/* </div> */}
                                                </Link>
                                            </ScrollAnimation>
                                        </center>
                                    </Grid>
                                 
                                </>

                            )
                        })
                        
                        : "")

                    :
                    category ? category.map(o => {
                        return (
                            <>
                                <Grid xs={10} sm={8} md={2} >
                                    <center>
                                        <ScrollAnimation animateIn='fadeInUp'>
                                            <Link
                                                to={{
                                                    pathname: "/product_list",
                                                    id: o.id,
                                                    hash: "#" + o.id,
                                                }}>
                                                <Card className={classes.card}>
                                                    <CardMedia image={o.image_path} className={classes.media} />
                                                </Card>
                                                <p style={{ marginTop: 'calc(1vw + 1vh)', marginBottom: 'calc(1vw + 1vh)', fontSize: 'calc(0.7vw + 1vh)' }}>{o.name}</p>
                                            </Link>
                                        </ScrollAnimation>
                                    </center>
                                </Grid>
                            </>
                        )
                    })

                        : ""}

                {lastItem != "product_categories" ?
                        <Grid md={12}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                            <br/>
                        <a href="/product_categories">
                                <Button size="small" className={classes.modalButton}> View More </Button>
                            </a>
                            </Grid>
                : ""}
                        
                        </>

                        : 
                        <CircularProgress style={{ color: "#1a237e", width: 'calc(3vw + 2.5vh)', height: 'calc(3vw + 2.5vh)', marginTop: '40vh' }} /> 
                }
            </Grid>
        </Box >
    );
}
