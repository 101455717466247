import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MyButton = styled(Button)({
    color: 'white',
    fontSize: 'calc(0.6vw + 1vh)',
    paddingTop: 'calc(0.1vw + 0.4vh)',
    paddingBottom: 'calc(0.1vw + 0.4vh)',
    paddingLeft: 'calc(0.4vw + 0.7vh)',
    paddingRight: 'calc(0.4vw + 0.7vh)',
});

export default function StyledComponents(props) {
    return <MyButton style={{ ...props }}>{props.value}</MyButton>;
}