import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link, useLocation } from "react-router-dom";
// import data from '../data/servicelist.json';
import cogoToast from "cogo-toast";
import axios from "axios";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ServicesForm from './ServicesForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card } from '@material-ui/core';

const styles = ({
    paper: {
        margin: '5%',
        padding: '1vw',
        width: '14vw',
    },
    popover: {
        pointerEvents: 'none',
    },
    headingText: {
        // background: '#1a237e',
        // color: 'white',
        textAlign: 'left',
        fontSize: 'calc(0.4vw + 1.2vh)',
        // marginRight: 'calc(0.3vw + 0.3vh)'
    },
    wrapper: {
        marginTop: 'calc(5vw + 5vh)',
        padding: 'calc(1vw)'
    }
});

export default function ControlledAccordions() {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    // const headings = data.service_group

    // useEffect(async () => {
    //     console.log("useEffect started");
    //     const res = await axios({
    //         url: `${process.env.REACT_APP_BASE_API}/User_Api/serviceList`,
    //         method: 'GET',
    //         headers: {
    //             Accept: 'application/json'
    //         },
    //     })
    //     if (res.data.status === "success" || res.data.status === true) {
    //         console.log("in if");
    //         console.log(res.data.data.service_group);
    //         console.log(typeof res.data.data.service_group);
    //         setData(res.data.data.service_group)
    //         setLoading(true)
    //     }
    //     else {
    //         console.log("in error", res);
    //         cogoToast.error(res.response)
    //     }
    //     console.log("useEffect end");
    // }, []);


    useEffect(() => {
        axios({
            url: `${process.env.REACT_APP_BASE_API}/User_Api/serviceList`,
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        })
            .then(res => {
                if (res.data.status === true) {
                    setData(res.data.data.service_group)
                    setLoading(true)
                }
            })
            .catch(error => {
                cogoToast.error(error.response)
            })
    }, []);

    // console.log(openedPopoverId)

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{
                    background: 'linear-gradient(to bottom right, rgba(253,95,0,0.7), rgba(253,158,0,0.7))',
                    minHeight: '100vh'
                }}
            >
                {console.log("in render")}
                {isLoading && data.length > 0 ?
                    <Grid xs={10} sm={6}>
                        {console.log("Inside list")}
                        <div style={styles.wrapper}>
                            <Card style={{ paddingRight: 'calc(3vw + 1vh)', paddingLeft: 'calc(3vw + 1vh)', paddingTop: 'calc(2vw + 1vh)', paddingBottom: 'calc(2vw + 1vh)' }} elevation={8}>

                                {console.log(isLoading, data, data.length)}
                                {data.map(o => {
                                    return (
                                        <Accordion
                                            style={{ margin: 0, border: 'none', boxShadow: 'none' }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: '#a0a0a0', fontSize: 'calc(0.5vvw + 1.2vh)' }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{ margin: 0, border: 'none' }}
                                            >
                                                <Typography style={styles.headingText}>{o.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: 'none' }}>
                                                <Typography style={styles.headingText}>
                                                    {o.sub_headings.map(ob => {
                                                        return (
                                                            <Link
                                                                to={{
                                                                    pathname: "/service_details",
                                                                    id: ob.id,
                                                                    hash: "#" + ob.id,
                                                                }}>
                                                                <p>{ob.name}</p>
                                                            </Link>
                                                        )
                                                    })}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </Card>
                        </div>
                    </Grid>
                    :
                    <CircularProgress style={{ color: "white", marginTop: "15vw", width: '10%', height: '10%' }} />
                }
            </Grid >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ paddingTop: '1vw', paddingBottom: '1vw', fontSize: 'calc(0.6vw + 0.6vh)' }}
                xs={12}>
                <span style={{ color: '#838383', fontSize: 'calc(0.5vw + 0.9vh)' }}>Powered by <a href="https://www.ronli.io" rel="noopener noreferrer" target="_blank" style={{ color: '#838383', fontSize: 'calc(0.5vw + 0.9vh)' }}>  RonLi </a> &copy; {(new Date().getFullYear())}</span>
            </Grid>
        </React.Fragment>
    );
}

