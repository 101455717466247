import React from 'react';
import List from '@material-ui/core/List';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
    AppBar, Grid, Link
} from '@material-ui/core';

const styles = {
    appBar: {
        backgroundColor: 'white',
        color: '#1a237e',
        padding: 0,
        boxShadow: 'none'
    },
    navMenu: {
        flexWrap: 'wrap',
    },
    list: {
        fontWeight: 400,
        alignItems: 'left',
        textAlign: 'left',
        padding: '3vh',
    },
    listItems: {
        color: 'white',
        display: 'block',
        paddingBottom: '1vw',
        cursor: 'pointer',
        fontSize: 'calc(0.9vw + 0.9vh)'
    },
    socialMedia: {
        color: '#29b6f6',
        paddingRight: 'calc(0.3vw + 0.5vh)'
    },
    socialMediaIcons: {
        fontSize: 'calc(1vw + 1vh)',
        color: 'white'
    },
    letsTalk: {
        color: '#29b6f6',
        margin: 0,
        paddingBottom: 'calc(0.7vw + 0.5vh)',
        fontFamily: 'Italianno',
        fontSize: 'calc(1.5vw + 1.8vh)',
    },
    headers: {
        fontSize: 'calc(1vw + 1.3vh)',
    }
};

export default function Footer() {

    return (
        <AppBar position="static" style={styles.appBar}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ paddingTop: '2vw', paddingBottom: '2vw', width: '100vw', backgroundImage: `url(${"./images/Wave.svg"})` }}
            >
                <Grid xs={6} sm={3}>
                    <img
                        src='./images/pmpl_logo.svg'
                        alt='Pmpl-Logo'
                        style={{ width: 'calc(12vw + 8vh)' }}
                        loading="lazy"
                    />
                </Grid>
                <Grid xs={6} sm={2}>
                    <List style={styles.list}>
                        {/* <h3 style={styles.headers} >COMPANY</h3> */}
                        <Link style={styles.listItems} href="javascript:void(0);">About</Link>
                        <Link style={styles.listItems} href="javascript:void(0);">Services</Link>
                        <Link style={styles.listItems} href="javascript:void(0);">Pricing</Link>
                        <Link style={styles.listItems} href="javascript:void(0);">FAQ</Link>
                    </List>
                </Grid>
                <Grid xs={6} sm={2}>
                    <List style={styles.list}>
                        {/* <h3 style={styles.headers} >COMPANY</h3> */}
                        <Link style={styles.listItems} href="javascript:void(0);">Contact</Link>
                        <Link style={styles.listItems} rel="noopener noreferrer" href="javascript:void(0);">Terms</Link>
                        <Link style={styles.listItems} rel="noopener noreferrer" href="javascript:void(0);">Privacy</Link>
                        <Link style={styles.listItems} href="/attributions">Attribution</Link>
                    </List>
                </Grid>
                <Grid xs={6} sm={2}>
                    <List style={styles.list}>
                        {/* <h3 style={styles.headers} >COMPANY</h3> */}
                        <Link style={styles.listItems} href="#about">Sitemap</Link>
                        <Link style={styles.listItems} rel="noopener noreferrer" href="javascript:void(0);">Blog</Link>
                        <Link style={styles.listItems} rel="noopener noreferrer" href="javascript:void(0);">Careers</Link>
                        <Link style={styles.listItems} href="javascript:void(0);">Help Center</Link>
                    </List>
                </Grid>
                <Grid
                    justify="center"
                    alignItems="center"
                    xs={6} sm={2}>
                    <Grid item >
                        <List style={styles.list}>
                            <h3 style={styles.letsTalk}>Follow Us</h3>
                            <Link style={styles.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pannchal_merrchant/"><InstagramIcon style={styles.socialMediaIcons} /></Link>
                            <Link style={styles.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/profile.php10000/"><FacebookIcon style={styles.socialMediaIcons} /></Link>
                            <Link style={styles.socialMedia} rel="noopener noreferrer" target="_blank" href="#"><TwitterIcon style={styles.socialMediaIcons} /></Link>
                            <Link style={styles.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/yogesh-panchal-56667465/"><LinkedInIcon style={styles.socialMediaIcons} /></Link>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ paddingTop: '1vw', paddingBottom: '1vw', backgroundColor: '#1a237e', fontSize: 'calc(0.6vw + 0.6vh)' }}
                xs={12}>
                <span style={{ color: 'white', fontSize: 'calc(0.5vw + 0.9vh)' }}>Powered by <a href="https://www.ronli.io" rel="noopener noreferrer" target="_blank" style={{ color: 'white', fontSize: 'calc(0.5vw + 0.9vh)' }}>  RonLi </a> &copy; {(new Date().getFullYear())}</span>
            </Grid>
        </AppBar >
    );
}