import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle'
import React from 'react';
import { useCountUp } from 'react-countup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import ScrollAnimation from 'react-animate-on-scroll';
import MyButton from './StyledComponents/MyButton'
import { FcDocument } from "react-icons/fc";
import { FaTruckMoving, FaHandshake, FaRegThumbsUp, FaSnowflake } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { useTheme } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        textAlign: 'justify',
        behavior: 'smooth'
    },
    // colorOverlay: {
    //     background: 'rgba(0, 132, 176, 0.4)'
    // },
    img: {
        width: '20%',
        padding: '7%',
    },
    textStyle: {
        textAlign: "justify",
        padding: '3%',
    },
    cards: {
        textAlign: "center",
        alignItems: 'center',
        padding: '2vw',
        textAlign: "center",
        alignItems: 'center',
        marginTop: '-25vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
    },
    root: {
        borderRadius: 12,
        minWidth: 256,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#fdfdfa'
        }
    },
    header: {
        textAlign: 'center',
        spacing: 10,
        fontWeight: '600'
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#1a237e',
        color: 'white'
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    divider: {
        marginTop: 'calc(1vw + 1vh)',
        marginBottom: 'calc(1vw + 1vh)'
    },
    colorOverlay: {
        height: '50vh',
        background: 'rgba(253,158,0,0.7)',
        [theme.breakpoints.down('sm')]: {
            height: '20vh'
        },
    },
}));

export default function Services(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { countUp, start, pauseResume, reset, update } = useCountUp({
        start: 0,
        end: 100,
        delay: 1000,
        duration: 5,
        onReset: () => console.log('Resetted!'),
        onUpdate: () => console.log('Updated!'),
        onPauseResume: () => console.log('Paused or resumed!'),
        onStart: ({ pauseResume }) => console.log(pauseResume),
        onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
        <div>
            <ScrollAnimation animateIn='fadeInUp' >

                <div onScroll={start} style={{ fontSize: 'calc(5vw + 5vh)' }}>{countUp}</div>
                <button >Start</button>
            </ScrollAnimation>

            {/* <button onClick={reset}>Reset</button>
            <button onClick={pauseResume}>Pause/Resume</button>
            <button onClick={() => update(2000)}>Update to 2000</button> */}
        </div>
    );
}