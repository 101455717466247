import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MainTitle from './StyledComponents/MainTitle';
import { Formik } from 'formik';
import * as yup from 'yup';
import cogoToast from "cogo-toast";
import category_data from "../data/product_catgory.json";
import product_data from "../data/product_details.json";
import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from "axios";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import EmailIcon from '@material-ui/icons/Email';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextField from '@material-ui/core/TextField';
import { Card, CircularProgress, Container, NativeSelect } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "white",
        borderRadius: '1vw',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modalButton: {
        border: '2px solid #1a237e',
        color: '#1a237e',
        fontSize: 'calc(0.5vw + 0.7vh)',
        // marginRight: 'calc(0.45vw + 0.3vh)',
        paddingLeft: 'calc(1vw + 1vh)',
        paddingRight: 'calc(1vw + 1vh)',
        borderRadius: '25px',
        fontWeight: 600,
        textTransform: 'none',
        "&:hover, &:focus": {
            border: '1px solid white',
            backgroundColor: "#1a237e",
            color: 'white'
        },
    },
    icon: {
        verticalAlign: 'bottom',
        fontSize: 'calc(1vw + 1.4vh)',
        marginRight: 'calc(0.4vw + 0.4vh)',
        color: '#1a237e',
        alignItems: 'left',
        textAlign: 'left',
    },
    infoLink: {
        verticalAlign: 'bottom',
        color: '#1a237e',
        alignItems: 'left',
        textAlign: 'left',
        fontSize: 'calc(0.6vw + 1.4vh)'
    },
    card: {
        padding: 'calc(4vw + 3vh)',
        paddingLeft: 'calc(3vh)',
        background: '#1a237e',
        color: 'white',
        borderRadius: '20px',
        borderTopLeftRadius: '120px',
        // padding: 'calc(1vw)'
    },
    risenCard: {
        padding: 'calc(4vw + 2vh)',
        background: 'white',
        color: '#1a237e',
        marginLeft: '-5vw',
        borderRadius: '20px',
        borderTopRightRadius: '120px',
    },
    letsTalk: {
        color: '#29b6f6',
        margin: 0,
        textAlign: 'left',
        paddingBottom: 'calc(0.7vw + 0.5vh)',
        fontFamily: 'Italianno',
        fontSize: 'calc(1.5vw + 1.8vh)',
    },
    list: {
        fontWeight: 400,
        alignItems: 'left',
        textAlign: 'left',
    },
    socialMedia: {
        color: '#1a237e',
        textAlign: 'left',
        paddingRight: 'calc(1vw + 1vh)'
    },
    socialMediaIcons: {
        textAlign: 'left',
        fontSize: 'calc(1.1vw + 1vh)',
        // color: 'white'
    },
    textField: {
        // borderRadius: 30,
        borderBottom: '1px solid #ffffff',
        fontSize: 'calc(0.6vw + 0.8vh)',
        // border: 0,
    },
    animatedUnderline: {
        width: '100px',
        height: '1px',
        background: 'white',
        transition: 'width 2s',
        "&:hover, &:focus": {
            width: '300px'
        },
    },
    wrapper: {
        marginTop: 'calc(6vw + 5vh)',
    }
}));

export default function ServicesForm(service_id, service_name, price) {
    const [token, setToken] = useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [Loader, setLoader] = React.useState(false);
    const [products, setProduct] = useState();
    const [category, setCategory] = useState();
    const lastItem = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const grecaptcha = window.grecaptcha
        grecaptcha.ready(function () {
            grecaptcha.execute('6LeEsdsaAAAAACMjI3KLuC65_w4kb7msVMpG_PaZ', {
                action: 'homepage'
            }).then(function (token) {
                setToken(token);
            });
        });
        axios({
            url: `${process.env.REACT_APP_BASE_API}/Api/product_category`,
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        })
            .then(res => {
                if (res.data.status === "success" || res.data.status === true) {
                    setCategory(res.data.data)
                    // isLoading(true)
                }
            })
            .catch(error => {
                // console.log("in error", error.response.data.password);
                // cogoToast.error(error.response.data.message)
            })
        // setCategory(category_data.data)
        setProduct(product_data.data)
    }, []);

    const validationSchema = yup.object({
        name: yup
            .string('Enter your name')
            .min(3, 'Name must be 3 characters or more')
            .matches(/^[aA-zZ\s]+$/, "Please enter a valid name")
            .required('First Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        // last_name: yup
        //     .string('Enter your last name')
        //     .min(1, 'Name must be 3 characters or more')
        //     .matches(/^[aA-zZ\s]+$/, "Please enter a valid name")
        //     .required('Last Name is required'),
        phone_no: yup
            .string('Enter your phone_no')
            // .matches(/^[0-9]$/, "Invalid phone number")
            .required('phone_no is required')
            .max(13, 'Invalid phone number')
            .min(9, 'Invalid phone number')
    });


    return (

        <Formik
            initialValues={{
                name: '',
                email: '',
                phone_no: '',
                file: '',
                product_category_id: '',
                product_id : 0,
                product_description: 'No description added',
                reToken: token,
                form_type: lastItem == "quotation" ? 1 : (lastItem == "register" ? 2 : (lastItem == "enquire" ? 1 : 0))
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { props }) => {
                setLoader(true)
                // console.log(props, values);
                handleToggle();
                values.reToken = token
                let formData = new FormData();
                formData.append("file", values.file);
                // delete values.file
                formData.append("json_payload", JSON.stringify(values))
                axios({
                    url: `${process.env.REACT_APP_BASE_API}Api/contactForm`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        Accept: 'application/json'
                    },
                })
                    .then(res => {
                        console.log(res);
                        if (res.data.status === "success" || res.data.status === true) {
                            // console.log("in if",res.data);
                            handleClose();
                            cogoToast.success(res.data.data);
                            setLoader(false)
                            setTimeout(function () { window.history.back(); }, 3000);
                        }
                        else {
                            // console.log("in else",res.data);
                            handleClose();
                            setLoader(false)
                            cogoToast.error(res.data.data);
                            // setTimeout(function () { window.location.reload(); }, 3000);
                        }
                        console.log(res.data.status);
                    })
                    .catch(error => {
                        // console.log("in error", error.response.data.password);
                        setLoader(false)
                        handleClose();
                        cogoToast.error(error.response.data.message)
                    })
            }}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    handleReset,
                } = props;

                return (
                    <Grid
                        direction="row"
                        container
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: '15vh' }}
                    >
                        {/* <Grid xs={12}>
                            <ScrollAnimation animateIn='fadeInUp'>
                                <MainTitle marginTop='calc(1vw + 3vh)' color='#1a237e' value={lastItem == "quotation" ? "Get a Free Quote" : (lastItem == "register" ? "Vendor Registration" : "Contact Us")} />
                            </ScrollAnimation>
                        </Grid> */}
                        <Grid xs={10} sm={5}>
                            <ScrollAnimation animateIn='fadeInLeft'>
                                <Card className={classes.card} elevation={8}>
                                    <form onSubmit={handleSubmit}>
                                        <Grid justify="center" alignItems="center" container spacing={3}>
                                            <Grid item xs={8} sm={8}>
                                                <MainTitle textAlign='left' color='white' value={lastItem == "quotation" ? "Get a Free Quote" : (lastItem == "register" ? "Vendor Registration" : (lastItem == "enquire" ? "Enquire Now" : "Contact Us"))} />
                                            </Grid>
                                            <br />
                                            <Grid item xs={8} sm={8}>
                                                <TextField
                                                    // variant="outlined"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    className={classes.textField}
                                                    name="name"
                                                    label="Name"
                                                    type="text"
                                                    id="name"
                                                    type="text"
                                                    autoComplete="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    error={touched.name && Boolean(errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={8} sm={8}>
                                                <TextField
                                                    // variant="outlined"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    name="email"
                                                    autoComplete="email"
                                                    className={classes.textField}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    error={touched.email && Boolean(errors.email)}
                                                    helperText={touched.email && errors.email}

                                                />
                                            </Grid>

                                            <Grid item xs={8} sm={8}>
                                                <TextField
                                                    className={classes.textField}
                                                    // variant="outlined"
                                                    size="small"
                                                    required
                                                    fullWidth
                                                    id="phone_no"
                                                    label="Phone no"
                                                    name="phone_no"
                                                    autoComplete="phone_no"
                                                    type="number"
                                                    value={values.phone_no}
                                                    onChange={handleChange}
                                                    error={touched.phone_no && Boolean(errors.phone_no)}
                                                    helperText={touched.phone_no && errors.phone_no}
                                                />

                                            </Grid>
                                            {lastItem != "contact" ?
                                                <Grid item xs={8} sm={8}>
                                                    <NativeSelect
                                                        className={classes.textField}
                                                        required
                                                        // variant="outlined"
                                                        size="small"
                                                        // defaultValue={0}
                                                        label="Product Category"
                                                        inputProps={{
                                                            name: 'product_category_id',
                                                            id: 'uncontrolled-native',
                                                        }}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        style={{ fontSize: 'calc(0.5vw + 1vh)', marginTop: 'calc(0.7vw + 0.7vh)' }}
                                                    >
                                                        {category ? category.map(o => {
                                                            return (
                                                                <option style={{ color: '#1a237e' }} value={o.id}>{o.name}</option>
                                                            )
                                                        })
                                                            : ""}
                                                        <option style={{ color: '#1a237e' }} value={0}>Other</option>
                                                    </NativeSelect>
                                                </Grid>
                                                :
                                                ""
                                            }
                                            {/* {console.log("Form val : ",values.product_category_id, values, lastItem)} */}
                                            {/* {(values.product_category_id != 0 || values.product_category_id != '')  && lastItem != "contact" ?
                                                < Grid item xs={8} sm={8}>
                                                    <NativeSelect
                                                        className={classes.textField}
                                                        required
                                                        // variant="outlined"
                                                        size="small"
                                                        // defaultValue={0}
                                                        label="Product Name"
                                                        inputProps={{
                                                            name: 'product_id',
                                                            id: 'uncontrolled-native',
                                                        }}

                                                        onChange={handleChange}
                                                        fullWidth
                                                        style={{ fontSize: 'calc(0.5vw + 1vh)', marginTop: 'calc(0.7vw + 0.7vh)' }}
                                                    >
                                                        {products ? products.map(o => {
                                                            return (
                                                                <option style={{ color: '#1a237e' }} value={o.id}>{o.name}</option>
                                                            )
                                                        })
                                                            : ""}
                                                    </NativeSelect>
                                                </Grid>
                                                :
                                                ""
                                            } */}
                                            {values.product_category == 0 && lastItem != "contact" ?
                                                <>
                                                    <TextField
                                                        className={classes.textField}
                                                        id="file"
                                                        name="file_upload"
                                                        accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        style={{ display: "none", visibility: "none" }}
                                                        className={classes.textField}
                                                        type="file"
                                                        onChange={(event) => {
                                                            setFieldValue("file", event.currentTarget.files[0]);
                                                        }}
                                                        error={touched.file && Boolean(errors.file)}
                                                        helperText={touched.file && errors.file}
                                                    />
                                                    <label htmlFor="file">
                                                        <Button
                                                            style={{ fontSize: 'calc(0.5vw + 0.6vh)' }}
                                                            variant="outlined"
                                                            component="span" >
                                                            {values.file ?
                                                                <CheckIcon style={{ color: '#009d00', padding: '1%' }} fontSize="small" />
                                                                :
                                                                <PublishIcon style={{ color: '#1a237e', padding: '1%' }} fontSize="small" />
                                                            }
                                                            Upload Sample Product
                                                        </Button>
                                                        <p style={{ color: 'red', margin: 0 }}>{touched.file && Boolean(errors.file)}
                                                            {touched.file && errors.file} </p>
                                                        {values.file ? values.file.name : ''}
                                                    </label>
                                                </>
                                                : ""}
                                            <Grid item xs={8} sm={8}>
                                                <TextField
                                                    className={classes.textField}
                                                    // variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    required
                                                    id="message"
                                                    label="Message"
                                                    name="message"
                                                    autoComplete="message"
                                                    multiline
                                                    rows={4}
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    error={touched.message && Boolean(errors.message)}
                                                    helperText={touched.message && errors.message}
                                                />
                                            </Grid>

                                            <Grid item xs={8} sm={8}>
                                            <label style={{fontSize: 'calc(0.5vw + 0.6vh)'}} class="m-2 p-3 h7">This site is protected by reCAPTCHA and the Google<a href="https://policies.google.com/privacy" style={{color:'rgb(41, 182, 246)'}} > Privacy Policy </a>and<a href="https://policies.google.com/terms" style={{color:'rgb(41, 182, 246)'}} > Terms of Service </a>apply. </label>
                                            </Grid>

                                            <Grid justify="center" alignItems="center" xs={8} sm={8} item >
                                                {/* <br /> */}
                                                <ScrollAnimation animateIn='fadeInUp'>
                                                    <Button
                                                        type="submit"
                                                        // fullWidth
                                                        disabled={Loader}
                                                        variant="contained"
                                                        className={classes.modalButton}
                                                    >
                                                        Submit <TrendingFlatIcon style={{ marginLeft: 'calc(0.45vw + 0.3vh)' }} /> {Loader ? <CircularProgress style={{ color: "white", width: 'calc(1vw + 0.5vh)', height: 'calc(1vw + 0.5vh)', marginLeft: '1vw' }} /> : ""}
                                                    </Button>
                                                </ScrollAnimation>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Card>
                            </ScrollAnimation>
                        </Grid>
                        <Grid xs={10} sm={3}>
                            <ScrollAnimation animateIn='fadeInRight'>
                                <Card className={classes.risenCard} elevation={8}>
                                    <MainTitle textAlign='left' color='#1a237e' value="Reach Us" />
                                    <div className={classes.animatedUnderline}> </div>
                                    <Grid
                                        justify="left"
                                        alignItems="left"
                                        xs={12}>
                                        <Grid item >
                                            <List className={classes.list}>
                                                <Link color="inherit" className={classes.infoLink} href="#about"><LocationOnIcon className={classes.icon} /><a href="#">P.No. 303/8 - B, 2nd Phase, G.I.D.C., Vapi - 396195, (Dist.) Valsad, Gujarat, India.</a></Link> <br /><br />
                                                <Link color="inherit" className={classes.infoLink} href="/"><EmailIcon className={classes.icon} /><a href="mailto:sales.india@pmpl.world">sales.india@pmpl.world</a></Link> <br /><br />
                                                <Link color="inherit" className={classes.infoLink} href="#about"><PhoneRoundedIcon className={classes.icon} /><a href="tel:+91-8469233555">+91-8469233555</a></Link> <br />
                                            </List>
                                        </Grid>

                                        <Grid item >
                                            <List className={classes.list}>
                                                {/* <h3 className={classes.letsTalk}>Social Media Handles</h3> */}
                                                <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pannchal_merrchant/"><InstagramIcon className={classes.socialMediaIcons} /></Link>
                                                <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/profile.php10000/"><FacebookIcon className={classes.socialMediaIcons} /></Link>
                                                <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="#"><TwitterIcon className={classes.socialMediaIcons} /></Link>
                                                <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/yogesh-panchal-56667465/"><LinkedInIcon className={classes.socialMediaIcons} /></Link>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </ScrollAnimation>
                        </Grid>
                    </Grid>
                );
            }}
        </Formik >
    );
}