import { Container, Grid, Box, Typography } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle';
import Services from './Services';
import ScrollAnimation from 'react-animate-on-scroll';
import { MdSecurity } from "react-icons/md";
import Card from '@material-ui/core/Card';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownCircle';
import CardMedia from '@material-ui/core/CardMedia';
import AboutServices from './AboutServices';
import AboutNumbers from './AboutNumbers';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const styles = {
    paperContainer: {
        height: '100vh',
        textAlign: 'justify'
    },
    colorOverlay: {
        background: 'linear-gradient(to bottom right, rgba(253,95,0,0.7), rgba(253,158,0,0.7))',
    },
    titleStyle: {
        fontWeight: 500,
        // textAlign: "left",
        fontFamily: 'Italianno',
        paddingTop: '5%',
        fontSize: 'calc(2vw + 2vh)',
    },
    textStyle: {
        color: '#6d8089',
        // textAlign: "left",
        fontSize: 'calc(0.4vw + 1.5vh)'
    },
    avatar: {
        // marginRight: 30,
        marginBottom: 5,
        marginTop: 10,
        width: '50%',
        // height: 'calc(calc(16vw + 16vh)',
        // float: "left"
    },
    media: {
        height: '100vh',
        // paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        // margin: 'calc(1vw + 1vh)',
        height: '100vh',
    },
    overlay: {
        position: 'absolute',
        top: '0%',
        // left: '20px',
        width: '80%',
        height: '100%',
        textAlign: 'left',
        color: 'white',
        // backgroundColor: 'rgba(0,0,0,0.2)'
    },
    productName: {
        position: 'absolute',
        top: '28%',
        width: '100%',
    }
};

function About(props) {
    return (
        <Box >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginTop: 'calc(0vw + 2vh)' }}
            >
                {/* <Grid xs={12}>
                    <ScrollAnimation animateIn='fadeIn'>
                    <MainTitle textAlign="center" marginTop='calc(0.6vw + 0.6vh)' color='#1a237e' value='ABOUT US' />
                    </ScrollAnimation>

                    <div class="linesss">
                    </div>
                </Grid> */}

                <Grid xs={12}>
                    {/* <img style={{ height: '47vh' }} alt="Mr. Yogesh Panchal" src="./images/about_bg.svg" /> */}
                    <Card style={styles.card}>
                        <ScrollAnimation animateIn='fadeIn'>
                            <CardMedia style={{ opacity: '0.3' }} image="./images/about_bg_1.svg" style={styles.media} />
                        </ScrollAnimation>
                        <Container>
                            <div style={styles.overlay}>
                                <div style={styles.productName}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    // style={{ marginTop: 'calc(3vw + 2vh)' }}
                                    >
                                        {/* <div id='services'>
                                            <Services />
                                        </div> */}
                                        <MainTitle textAlign="center" marginTop='calc(4vw + 3vh)' color='#1a237e' value='We specialize in importing and exporting goods at a global scale. We ensure safe and secure transport of products and offer the best prices in the market. ' />

                                        <div style={{ marginTop: '5vw' }} class="floating">
                                            {/* <ScrollAnimation animateIn='bounce' duration={4}> */}
                                            <a href="#our_services"> <ArrowDropDownIcon style={{ fontSize: 'calc(2vw + 2vh)', color: '#1a237e' }} /> </a>
                                            {/* </ScrollAnimation> */}
                                        </div>
                                    </Grid>
                                </div>
                            </div>
                        </Container>
                    </Card>
                </Grid>

                <Grid xs={12}>
                    <div id="our_services">

                        <AboutServices />
                    </div>

                    {/* <AboutNumbers /> */}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="center"
                // style={{ paddingLeft: 'calc(3vw + 3vh)', paddingRight: 'calc(3vw + 3vh)' }}
                >
                    <Grid xs={12} sm={6} style={{ padding: 'calc(2vw + 1vh)', paddingLeft: 'calc(10vw + 8vh)' }}>
                        <center>
                            <img style={styles.avatar} alt="Mr. Yogesh Panchal" src={`${process.env.REACT_APP_BASE_API}assets/images/profile2.png`} />
                            <p style={styles.titleStyle} >Yogesh Panchal</p>
                            <p style={styles.textStyle}>FOUNDER &amp; DIRECTOR</p>
                        </center>
                        <p style={{ textAlign: 'center', marginTop: '2%' }}>Mr. Yogesh Panchal is a young and dynamic entrepreneur. He has a proven track record of 21 years of experience in the field of domestic &amp; international trade. He is the second-generation entrepreneur, who took the firm from traditional business model to world class organisation. He has transformed the way their family business operation into professional work culture. It is his visionary approach that PMPL is been materialized. He is a firm believer that talent coupled with ethical approach is the key to long term business success. He works with the mission to break all the political boundaries and aims at promoting international trade, as he believes that it is economics that unites the entire world together. At PMPL he looks after activating new avenues in terms of products, local representatives, technology &amp; countries.</p>
                    </Grid>


                    <Grid xs={12} sm={6} style={{ padding: 'calc(2vw + 1vh)', paddingRight: 'calc(10vw + 8vh)' }}>
                        <center>
                            <img style={styles.avatar} alt="Jigenesha Panchal" src={`${process.env.REACT_APP_BASE_API}assets/images/profile1.png`} />
                        </center>
                        <Grid xs={12}>
                            <p style={styles.titleStyle}>Jigenesha Panchal</p>
                            <p style={styles.textStyle}>JOINT DIRECTOR</p>
                            <p style={{ textAlign: 'center', marginTop: '2%' }}>Mrs. Jignesha Panchal is a multi-talented director of PMPL. She is the backbone of PMPL. She is a computer engineer graduate and shares her wisdom in technology at PMPL. She has vast experience in diverse markets, she knows the in and out of customer experience, and market trends. She keeps her keen eye on the economy and brings strategic changes at PMPL. She bestows PMPL by her tenacious management skills and generous human resource skills.</p>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </Box >
    );
}

export default withStyles(styles)(About);