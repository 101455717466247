import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import cogoToast from "cogo-toast";
import axios from "axios";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import data from "../data/product_details.json";
import { Box, Card, Container } from '@material-ui/core';
import ServicesForm from './ServicesForm';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%'
    },
    paper: {
        backgroundColor: "white",
        borderRadius: '1vw',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    submit: {
        // backgroundColor: '#11ABE8',
        background: 'linear-gradient(to bottom right, #29b6f6, #fd9e00)',
        color: 'white',
        // marginBottom: '2vw',
        // width: 'calc(3vw + 3vh)',
    },
    card: {
        padding: 'calc(1vw + 1vh)',
        margin: 'calc(1vw + 1vh)',
        height: 'calc(6vw + 13vh)'
    },
    textStyle: {
        fontSize: 'calc(0.55vw + 0.7vh)',
    },
    textField: {
        borderRadius: 30,
        borderColor: '#11ABE8',
        fontSize: 'calc(0.6vw + 0.8vh)',
        border: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    wrapper: {
        marginTop: 'calc(4vw + 5vh)',
    }
}));

export default function ServicesDetails(props) {
    const [loading, isLoading] = useState(false);
    const classes = useStyles();
    const [category, setData] = useState();
    // let locations = useLocation();
    // const [location, setLocation] = useState((locations.hash).substring(1));

    useEffect(() => {
        // axios({
        //     url: `${process.env.REACT_APP_BASE_API}/User_Api/showService/` + location,
        //     method: 'GET',
        //     headers: {
        //         Accept: 'application/json'
        //     },
        // })
        //     .then(res => {
        //         if (res.data.status === "success" || res.data.status === true) {
        //             setData(res.data.data)
        //             isLoading(true)
        //         }
        //     })
        //     .catch(error => {
        //         // console.log("in error", error.response.data.password);
        //         cogoToast.error(error.response.data.message)
        //     })
        setData(data.data)
        console.log(category);
    }, []);


    return (
        <Box className={classes.wrapper}>
            {/* {loading == true ? */}
            {console.log(category)}
            <React.Fragment>
                <Grid
                    direction="row"
                    container
                    justify="center"
                    alignItems="center"
                // style={{ height: 'calc(12vw + 21vh)', background: 'rgba(253,158,0,0.7)' }}

                >
                    <p> Sharon </p>

                    {category ? category.map(o => {
                        return (
                            <p> Sharon </p>
                        )
                    })
                        : ""}
                </Grid>
                {/* <Grid
                        direction="row"
                        container
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: '-4vw', marginBottom: 'calc(1vw + 3vh)' }}

                    >
                        <Grid xs={9} sm={4}>
                            <ServicesForm price={data.price} service_name={data.service_name} service_id={location} />
                        </Grid>
                    </Grid> */}


                {/* <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ padding: '2vw' }}
                >
                    <h2 > {data.id} </h2>
                    <p style={{ textAlign: 'center' }}> {data.name} </p>

                </Grid> */}
                {/* <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ padding: '2vw' }}
                >
                    {data.document ? data.document.map(o => {
                        return (
                            <Grid xs={10} sm={3}>
                                <Card className={classes.card}>
                                    <img
                                        src='../../images/document.svg'
                                        alt='Zephyr-Logo'
                                        style={{ width: '20%' }}
                                        loading="lazy"
                                    />
                                    <h4> {o.name} </h4>
                                    <p className={classes.textStyle}> {o.description} </p>
                                </Card>
                            </Grid>
                        )
                    })
                        :
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }
                </Grid> */}

            </React.Fragment>
            {/* //     :
            //     <CircularProgress style={{ color: "#29b6f6", marginTop: "15vw", width: '10%', height: '10%' }} />

            // } */}
        </Box >
    );
}