import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { useFormik } from 'formik';
import * as yup from 'yup';
import cogoToast from "cogo-toast";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%'
    },
    paper: {
        backgroundColor: "white",
        borderRadius: '1vw',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    submit: {
        // backgroundColor: '#11ABE8',
        background: 'linear-gradient(to bottom right, #29b6f6, #fd9e00)',
        color: 'white',
        margin: '1vw',
        fontWeight: 600,
        fontSize: 'calc(0.5vw + 0.7vh)'
    },
    textField: {
        borderRadius: 30,
        borderColor: '#11ABE8',
        fontSize: 'calc(0.6vw + 0.8vh)',
        border: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function LoginForm(props) {
    const [reToken, setreToken] = useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [Loader, setLoader] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const forgot_password = process.env.REACT_APP_BASE_API + '/dashboard'


    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
        setLoader(!Loader);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const recaptcha = () => {
        const grecaptcha = window.grecaptcha
        grecaptcha.ready(function () {
            grecaptcha.execute('6LeEsdsaAAAAACMjI3KLuC65_w4kb7msVMpG_PaZ', {
                action: 'homepage'
            }).then(function (reToken) {
                setreToken(reToken);
            });
        });
    }

    useEffect(() => {
        recaptcha();
    }, []);

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            .min(8, 'Password must be 8-10 characters')
            .max(10, 'Password must be 8-10 characters')
            .required('password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            reToken: reToken
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleToggle();
            values.reToken = reToken

            axios({
                url: `${process.env.REACT_APP_BASE_API}/User_Api/login`,
                method: 'POST',
                data: JSON.stringify(values),
                headers: {
                    Accept: 'application/json'
                },
            })
                .then(res => {
                    console.log(res);
                    if (res.data.status === "success" || res.data.status === true) {
                        handleToggle();
                        props.handleClose();
                        cogoToast.success(res.data.message);
                        setTimeout(function () { window.location.replace(res.data.uri) }, 2000);
                    }
                    else {
                        setLoader(false)
                        cogoToast.error(res.data.message);
                        recaptcha();
                        // setTimeout(function () { window.location.reload(); }, 2000);
                    }
                    console.log(res.data.status);
                })
                .catch(error => {
                    console.log("in error", error);
                    setLoader(false)
                    cogoToast.error(error.response.data.message)
                    recaptcha();
                })
        },
    });

    return (

        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
            <Grid justify="center" alignItems="center" container spacing={2}>
                <Grid item xs={11} sm={8}>
                    <TextField
                        // variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        className={classes.textField}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}

                    />
                </Grid>
                <Grid item xs={11} sm={8} style={{ display: 'inline-block' }}>
                    <TextField
                        // variant="outlined"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="password"
                        className={classes.textField}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                    />
                </Grid>

                <Grid justify="center" alignItems="center" item xs={8} sm={8}>
                    <center>
                        <Button
                            type="submit"
                            disabled={Loader}
                            variant="contained"
                            className={classes.submit}
                        >

                            LOGIN {Loader ? <CircularProgress style={{ color: "white", width: 'calc(1vw + 0.5vh)', height: 'calc(1vw + 0.5vh)', marginLeft: '1vw' }} /> : ""}

                        </Button>
                    </center>
                </Grid>
            </Grid>
        </form>
    );
}