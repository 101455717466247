import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountContext } from "../Context.js";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Avatar, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import LoginTabs from './LoginTabs';
import MyButton from '../StyledComponents/MyButton'
import SignUpForm from './SignUpForm';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%'
    },
    paper: {
        backgroundColor: "white",
        borderRadius: '1vw',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    submit: {
        // backgroundColor: '#11ABE8',
        background: 'linear-gradient(to bottom right, #29b6f6, #fd9e00)',
        color: 'white',
        marginBottom: '2vw',
        width: 'calc(4vw + 4vh)',
        fontWeight: 600
    },
    textField: {
        borderRadius: 30,
        borderColor: '#11ABE8',
        fontSize: 'calc(0.6vw + 0.8vh)',
        border: 0,
    },
    modalButton: {
        background: '#29b6f6',
        color: 'white',
        fontSize: 'calc(0.6vw + 0.8vh)',
        marginRight: 'calc(0.45vw + 0.3vh)'
    }
}));



export default function TransitionsModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [accountType, setAccountType] = useState(0)
    const [openSignUP, setopenSignUP] = useState(0)

    const handleOpen = () => {
        if (props.account_type) {
            setAccountType(props.account_type)
            setopenSignUP(1)
        }
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setLogo("../../images/OneCall_Logo_circular.svg");
        setLoading(true)
    }, []);

    return (
        <div style={{ display: 'inline-block' }}>
            <a onClick={handleOpen}><MyButton background={props.color} marginRight='calc(0.5vw + 0.3vh)' value={props.text} /></a>
            {isLoading ?
                <Modal
                    disableBackdropClick
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >

                    <Fade in={open}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            xs={8}
                            sm={6}
                            md={4}
                            className={classes.paper}
                        >
                            <Grid xs={12}>
                                <center>
                                    <Avatar src={logo}
                                        alt="Email icon"
                                        style={{ width: "calc(4vw + 6vh)", height: "calc(4vw + 6vh)", backgroundColor: '#ffffff', border: '2px solid #29b6f6', marginTop: '-8vh' }}
                                    >

                                    </Avatar>
                                </center>
                                <div >
                                    <a onClick={handleClose} style={{ float: 'right', marginTop: '-2vw', color: '#ff9c60' }}><CloseIcon style={{ fontSize: 'calc(0.8vw + 1vh)', }} /></a>
                                </div>
                            </Grid>
                            <Grid>
                                <AccountContext.Provider value={accountType}>
                                    <LoginTabs handleClose={handleClose} handleToggle={handleToggle} signup={openSignUP} />
                                </AccountContext.Provider>
                            </Grid>
                        </Grid>
                    </Fade>
                </Modal>
                : ''
            }
        </div >
    );
}