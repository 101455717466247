import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, List, ListItem, Link,
  withStyles, Grid, SwipeableDrawer, CircularProgress
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import SignupModal from '../modals/SignUpModal';
import EmailIcon from '@material-ui/icons/Email';
// import { Link as Links } from 'react-scroll'

const styleSheet = {

  list: {
    width: 400
  },
  padding: {
    marginRight: 'calc(1vw + 0.6vh)',
    marginLeft: 'calc(1vw + 0.6vh)',
    fontWeight: 600,
    cursor: 'pointer',
    color: '#1a237e'
  },

  sideBarIcon: {
    padding: 0,
    color: "white",
    cursor: "pointer",
  },

  icon: {
    verticalAlign: 'bottom',
    fontSize: 'calc(0.6vw + 1.4vh)',
    marginRight: 'calc(0.4vw + 0.4vh)',
    color: '#1a237e'
  },
  infoLink: {
    verticalAlign: 'bottom',
    color: '#959ca0',
    fontSize: 'calc(0.4vw + 1.4vh)',
    paddingLeft: 'calc(1vw + 1vh)',
    paddingRight: 'calc(1vw + 1vh)'
  },
  modalButton: {
    background: '#1a237e',
    color: 'white',
    fontSize: 'calc(0.5vw + 0.7vh)',
    marginRight: 'calc(0.45vw + 0.3vh)',
    paddingLeft: 'calc(1vw + 1vh)',
    paddingRight: 'calc(1vw + 1vh)',
    borderRadius: '25px',
    fontWeight: 600,
    textTransform: 'none',
    "&:hover, &:focus": {
      // transform: 'scale(1.05)',
      backgroundColor: '#131a5d',
      color: 'white',
      // boxShadow: '0 0 10px #959ca0, 0 0 1px #959ca0, 0 0 1px #959ca0, 0 0 1px #959ca0'
      // textShadow: '2px 2px #ffffff'
      boxShadow: '0 0.5em 0.5em -0.4em #959ca0',
      // transition: '0.25s',
      // transform: 'translateY(0.75em)',
    },
  },
  headerAppbar: {
    color: '#1a237e',
    backgroundColor: 'white',
    padding: 'calc(0.4vw + 0.7vh)',
    fontWeight: 500
  },
  logoAppbar: {
    color: '#1a237e',
    backgroundColor: 'white',
    fontWeight: 500
  }
}

class ResponsiveDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerActivate: false, drawer: false, isLoggedIn: false, apiDone: false };
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  // componentDidMount() {
  //   axios({
  //     url: `${process.env.REACT_APP_BASE_API}/User_Api/isLoggedIn`,
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json'
  //     },
  //   })
  //     .then(res => {
  //       if (res.data.status === "success" || res.data.status === true || res.data.status === false) {
  //         this.setState({
  //           isLoggedIn: res.data.status,
  //           apiDone: true
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       console.log("in error", error);
  //       this.setState({
  //         apiDone: true
  //       });
  //     })
  // }

  componentWillMount() {
    if (window.innerWidth <= 650) {
      this.setState({ drawerActivate: true });
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 650) {
        this.setState({ drawerActivate: true });
      }
      else {
        this.setState({ drawerActivate: false })
      }
    });
  }

  //Small Screens
  createDrawer() {
    const { classes } = this.props
    const dashboard_link = process.env.REACT_APP_BASE_API + '/dashboard'
    return (
      <div>
        <AppBar>
          <Grid className={classes.logoAppbar} container direction="row" justify="space-between" alignItems="center">
            <a href="/">
              <img
                src='./images/pmpl_logo.svg'
                alt='PMPL-logo'
                style={{ width: 'calc(5vw + 5vh)', margin: '2vw' }}
                loading="lazy"
              />
            </a>
            <Grid justify="flex-end" alignItems="center">
              {this.state.isLoggedIn
                ?
                <a href="#">
                  <Button style={{ marginRight: 'calc(1vw + 0.5vh)' }} className={classes.modalButton}> Dashboard </Button>
                </a>
                :
                (this.state.apiDone ? <SignupModal text="Sign in" color='#29b6f6' /> : '')
              }
              <MenuIcon
                className={this.props.classes.sideBarIcon}
                style={{ color: '#1a237e', verticalAlign: 'bottom', paddingRight: '2vw' }}
                onClick={() => { this.setState({ drawer: true }) }} />
            </Grid>
          </Grid>
        </AppBar>

        <SwipeableDrawer
          // style={{ width: '100%' }}
          anchor='top'
          open={this.state.drawer}
          onClose={() => { this.setState({ drawer: false }) }}
          onOpen={() => { this.setState({ drawer: true }) }}>

          <div
            tabIndex={0}
            role="button"
            onClick={() => { this.setState({ drawer: false }) }}
            onKeyDown={() => { this.setState({ drawer: false }) }}>

            <List className={this.props.classes.list}>
              <ListItem key={1} button ><Link color="inherit" className={classes.padding} href="/Home">Home</Link> </ListItem>
              <ListItem key={2} button > <Link color="inherit" className={classes.padding} href="/About">About</Link> </ListItem>
              <ListItem key={3} button > <Link color="inherit" className={classes.padding} href="/Contact" >Contact</Link> </ListItem>
              <ListItem key={3} button > <Link color="inherit" className={classes.padding} href="/product_categories">Products</Link> </ListItem>
              <ListItem key={7} button > <Link color="inherit" className={classes.infoLink} href="#"><EmailIcon className={classes.icon} /><a href="mailto:sales.india@pmpl.world">sales.india@pmpl.world</a></Link> </ListItem>
              <ListItem key={8} button > <Link color="inherit" className={classes.infoLink} href="#"><PhoneRoundedIcon className={classes.icon} /><a href="tel:+918469233555">+91-8469233555</a></Link> </ListItem>
            </List>

          </div>
        </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  destroyDrawer() {
    const { classes } = this.props
    return (
      <AppBar>
        <Grid className={classes.logoAppbar} container direction="row" justify="space-between" alignItems="center">
          <Grid justify="flex-start" alignItems="center">
            <a href="/">
              <img
                src='./images/pmpl_boat.svg'
                alt='PMPL-logo'
                href="/"
                style={{ width: 'calc(5vw + 5vh)', paddingLeft: '2vw', margin: '0.5vw' }}
                loading="lazy"
              />
            </a>
          </Grid>

          <Link color="inherit" className={classes.infoLink} href="/"><EmailIcon className={classes.icon} /><a href="mailto:sales.india@pmpl.world">sales.india@pmpl.world</a></Link>
          <Link color="inherit" className={classes.infoLink} href="#about"><PhoneRoundedIcon className={classes.icon} /><a href="tel:+918469233555">+91-8469233555</a></Link>


          <Grid alignItems="center">
            <Link color="inherit" className={classes.padding} href="/Home" >Home</Link>
            <Link color="inherit" className={classes.padding} href="/About">About</Link>
            <Link color="inherit" className={classes.padding} href="/Contact" >Contact</Link>
            <Link color="inherit" className={classes.padding} href="/product_categories">Products</Link>

            <a href="/quotation">
              <Button style={{ marginRight: 'calc(1vw + 0.5vh)' }} className={classes.modalButton}> Get a Free Quote <EmailIcon style={{ color: 'white', marginLeft: 'calc(0.5vw + 0.5vh)', marginRight: 0 }} className={classes.icon} /></Button>
            </a>
          </Grid>
        </Grid>

      </AppBar>
    )
  }

  render() {
    return (
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};



export default withStyles(styleSheet)(ResponsiveDrawer);