import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountContext } from "../Context.js";
import { Avatar, Button, Container, NativeSelect } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik';
import * as yup from 'yup';
import cogoToast from "cogo-toast";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%'
    },
    paper: {
        backgroundColor: "white",
        borderRadius: '1vw',
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    submit: {
        background: 'linear-gradient(to bottom right, #29b6f6, #fd9e00)',
        color: 'white',
        margin: '1vw',
        fontSize: 'calc(0.5vw + 0.7vh)',
        fontWeight: 600
    },
    textField: {
        borderRadius: 30,
        borderColor: '#11ABE8',
        fontSize: 'calc(0.6vw + 0.8vh)',
        border: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default function SignUpForm(props) {
    const [token, setToken] = useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [Loader, setLoader] = React.useState(false);
    const [duplicate, setDuplicate] = React.useState(false);
    const account_type = React.useContext(AccountContext);

    const handleClose = () => {
        setOpen(false);
        setLoader(false);
    };

    const handleToggle = () => {
        setOpen(!open);
        setLoader(!Loader);
    };

    useEffect(() => {
        const grecaptcha = window.grecaptcha
        grecaptcha.ready(function () {
            grecaptcha.execute('6LeEsdsaAAAAACMjI3KLuC65_w4kb7msVMpG_PaZ', {
                action: 'homepage'
            }).then(function (token) {
                setToken(token);
            });
        });
    }, []);

    const handleBlur = (e) => {
        if (e.target.value != '') {
            axios({
                url: `${process.env.REACT_APP_BASE_API}/User_Api/checkmail`,
                method: 'POST',
                data: JSON.stringify({ 'email': e.target.value, 'reToken': token }),
                headers: {
                    Accept: 'application/json'
                },
            })
                .then(res => {
                    console.log(res);
                    if (res.data.status === "success" || res.data.status === true) {
                        console.log("valid email");
                        setDuplicate(false);
                        // cogoToast.success(res.data.message);
                        // setTimeout(function () { window.location.reload(); }, 2000);
                    }
                })
                .catch(error => {
                    // console.log("in error", error.response.data.password);
                    setDuplicate(true);
                    cogoToast.error(error.response.data.message)
                })
        }
    };


    const validationSchema = yup.object({
        name: yup
            .string('Enter your name')
            .min(3, 'Name must be 3 characters or more')
            .matches(/^[aA-zZ\s]+$/, "Please enter a valid name")
            .required('Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            .min(8, 'Password must be 8-10 characters')
            .max(10, 'Password must be 8-10 characters')
            .required('password is required'),
        referred_by: yup
            .string('Enter your referred_by'),
        phone_no: yup
            .string('Enter your phone_no')
            // .matches(/^[0-9]$/, 'Invalid phone number')
            .required('phone_no is required')
            .max(12, 'Invalid phone number')
            .min(10, 'Invalid phone number')
    });


    return (

        <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
                referred_by: '',
                phone_no: '',
                account_type: account_type ? account_type : 0,
                token: token
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleToggle();
                values.reToken = token

                axios({
                    url: `${process.env.REACT_APP_BASE_API}/User_Api/register`,
                    method: 'POST',
                    data: JSON.stringify(values),
                    headers: {
                        Accept: 'application/json'
                    },
                })
                    .then(res => {
                        console.log(res);
                        if (res.data.status === "success" || res.data.status === true) {
                            props.handleClose();
                            cogoToast.success(res.data.message);
                            // setTimeout(function () { window.location.reload(); }, 2000);
                        }
                        else {
                            props.handleClose();
                            cogoToast.error(res.data.message);
                            // setTimeout(function () { window.location.reload(); }, 2000);
                        }
                        console.log(res.data.status);
                    })
                    .catch(error => {
                        // console.log("in error", error.response.data.password);
                        props.handleClose();
                        cogoToast.error(error.response.data.message)
                    })
            }}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    handleReset,
                } = props;

                return (
                    <form onSubmit={handleSubmit}>
                        <Grid justify="center" alignItems="center" container spacing={2}>
                            <Grid item xs={8} >
                                <TextField
                                    // variant="outlined"
                                    required
                                    fullWidth
                                    className={classes.textField}
                                    name="name"
                                    label="Name"
                                    type="text"
                                    id="name"
                                    type="text"
                                    autoComplete="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>

                                <TextField
                                    // variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className={classes.textField}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}

                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    // variant="outlined"
                                    required
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    autoComplete="password"
                                    type="password"
                                    className={classes.textField}
                                    value={values.password}
                                    onChange={handleChange}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    // variant="outlined"
                                    required
                                    fullWidth
                                    id="phone_no"
                                    label="Phone no"
                                    name="phone_no"
                                    autoComplete="phone_no"
                                    type="number"
                                    value={values.phone_no}
                                    onChange={handleChange}
                                    error={touched.phone_no && Boolean(errors.phone_no)}
                                    helperText={touched.phone_no && errors.phone_no}
                                />

                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <NativeSelect
                                    required
                                    defaultValue={account_type ? account_type : 0}
                                    inputProps={{
                                        name: 'account_type',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={handleChange}
                                    fullWidth
                                    style={{ fontSize: 'calc(0.5vw + 1vh)', marginTop: 'calc(0.7vw + 0.7vh)' }}
                                >
                                    <option style={{ color: '#ffffff' }} value={0}>Free</option>
                                    <option style={{ color: '#ffffff' }} value={1}>Premium</option>
                                    <option style={{ color: '#ffffff' }} value={2}>Ultimate</option>
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                                <TextField
                                    // variant="outlined"
                                    // required
                                    fullWidth
                                    id="referred_by"
                                    label="Referred By"
                                    name="referred_by"
                                    autoComplete="referred_by"
                                    value={values.referred_by}
                                    onChange={handleChange}
                                    error={touched.referred_by && Boolean(errors.referred_by)}
                                    helperText={touched.referred_by && errors.referred_by}
                                />
                            </Grid>
                            <Grid justify="center" alignItems="center" item xs={8} sm={8}>
                                <center>
                                    {duplicate ? <p style={{ color: 'red', fontSize: 'calc(0.5vw + 1vh)' }}> Email id already exists ! </p> : ''}
                                    <Button
                                        type="submit"
                                        // fullWidth
                                        disabled={duplicate || Loader}
                                        variant="contained"
                                        className={classes.submit}
                                    >
                                        Submit {Loader ? <CircularProgress style={{ color: "white", width: 'calc(1vw + 0.5vh)', height: 'calc(1vw + 0.5vh)', marginLeft: '1vw' }} /> : ""}
                                    </Button>
                                </center>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
}