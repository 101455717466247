import { Box, Container, Grid, Typography, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import Card from '@material-ui/core/Card';
import Services from './Services';
import Product from './Products';
import ImportExport from './ImportExport';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: '-7vw',
    },
    media: {
        height: '100%',
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: 'calc(1vw + 1vh)',
        width: '220px',
        height: '220px',
    },
    titleStyle: {
        color: '#1a237e',
        lineHeight: '120%',
        fontWeight: 400,
        fontSize: 'calc(3vw + 3vh)',
        fontFamily: 'Faster One'
    },
    iconBar: {
        position: "fixed",
        top: "55%",
        // marginLeft: 'calc(1vw + 1vh)',
        // -webkit-transform: translateY(-50%),
        // -ms-transform: translateY(-50%),
        transform: "translateY(-50%)",
    },
    socialMedia: {
        color: 'white',
    },
    socialMediaIcons: {
        padding: 'calc(0.3vw + 0.3vh)',
        margin: 'calc(0.3vw + 0.3vh)',
        display: 'block',
        zIndex: 1,
        fontSize: 'calc(1.6vw + 1.5vh)',
        borderBottom: '1px solid #ffffff',
        borderRadius: '50%',
        "&:hover, &:focus": {
            backgroundColor: '#1a237e',
        }
    },
    textStyle: {
        color: '#959ca0',
        lineHeight: '140%',
        fontWeight: 600,
        textAlign: 'center',
        fontSize: 'calc(1.3vw + 1.5vh)'
    }
}));

export default function Home() {
    const classes = useStyles();

    return (
        <div>
            <div>
                <Box className={classes.wrapper}>
                    <div className={classes.iconBar}>
                        {/* <Card style={{backgroundColor:'#1a237e', padding: 'calc(0.3vw + 0.3vh)', margin: 'calc(0.3vw + 0.3vh)'}}>
                        <p style={{color:'white'}}>Website development<br/> in progress</p>
                        </Card>
                        <br/> */}
                        <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pannchal_merrchant/"><InstagramIcon style={{ background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' }} className={classes.socialMediaIcons} /></Link>
                        <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/profile.php10000/"><FacebookIcon style={{ backgroundColor: '#4267B2' }} className={classes.socialMediaIcons} /></Link>
                        <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://wa.me/+918469233555"><WhatsAppIcon style={{ backgroundColor: '#25D366' }} className={classes.socialMediaIcons} /></Link>
                        <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="#"><TwitterIcon style={{ backgroundColor: '#00acee' }} className={classes.socialMediaIcons} /></Link>
                        <Link className={classes.socialMedia} rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/yogesh-panchal-56667465/"><LinkedInIcon style={{ backgroundColor: '#0077b5' }} className={classes.socialMediaIcons} /></Link>
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: '23vh' }}
                    >
                        <Grid xs={12} sm={6}>
                            {/* <CustomSlider /> */}
                            <img
                                src='./images/landing_page_img.svg'
                                alt='PMPL-Illustration'
                                style={{ width: '92%' }}
                            />

                        </Grid>
                        <Grid xs={12} sm={6}
                            style={{ paddingRight: 'calc(2vw + 3vh)' }}
                        >
                            {/* <MainTitle color='#1a237e' fontSize="calc(1vw + 1vh)" fontWeight='500' value='PANNCHAL MERRCHANTS' /> */}
                            {/* <MainTitle color='#1a237e' value='PMPL' /> */}
                            <Typography className={classes.titleStyle}>
                                PMPL
                            </Typography>
                            <Typography className={classes.textStyle}>
                                Global Trade made Easy !
                            </Typography>
                            <ScrollAnimation animateIn='fadeInUp'>
                                <ImportExport />
                            </ScrollAnimation>
                        </Grid>
                    </Grid>

                </Box>

            </div>
            <div id='services'>
                <Services />
            </div>
            <div id='product'>
                <Product />
            </div>
            {/*
            <div id='testimonial'>
                <Testimonial />
            </div>
            <div>
                <Footer />
            </div> */}

        </div >
    );
}