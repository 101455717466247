import React from 'react';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const MainTitle = styled(Typography)({
    fontWeight: 400,
    // lineHeight: 'calc(3vw + 3vh)',
    textAlign: "center",
    alignItems: 'center',
    marginBottom: 'calc(0.5vw + 0.5vh)',
    fontSize: 'calc(1.1vw + 2.2vh)',
    fontFamily: 'Comfortaa',
    // fontFamily: 'Monserrat, sans-serif'
});

export default function StyledComponents(props) {
    return <MainTitle style={{ ...props }}>{props.value}</MainTitle>;
}