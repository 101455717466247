// import logo from './logo.svg';
import Grid from '@material-ui/core/Grid';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ResponsiveDrawer from './StyledComponents/ResponsiveDrawer';
import Home from './Home';
import About from './About';
import Services from './Services';
import ServicesPop from './ServicesPop';
import ServicesForm from './ServicesDetails';
import Contact from './ServicesForm';
import SingleProduct from './SingleProduct';
import ProductDetails from './ProductDetails';
import Products from './Products';
import Attributions from './modals/Attributions';
import Footer from './Footer';

export default function ServicesLayout() {
    return (
        <Router>
            <div className="App">
                <Grid
                    direction="row"
                >
                    {/* <LogoDrawer /> */}
                    <ResponsiveDrawer />
                    <Switch>
                        <Route path="/About">
                            <About />
                        </Route>
                        <Route path="/Services">
                            <Services />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/Home">
                            <Home />
                        </Route>
                        <Route path="/service_list">
                            <ServicesPop />
                        </Route>
                        <Route path="/service_details">
                            <ServicesForm />
                        </Route>
                        <Route path="/attributions">
                            <Attributions />
                        </Route>
                        <Route path="/quotation">
                            <Contact />
                        </Route>
                        <Route path="/enquire">
                            <Contact />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                        <Route path="/register">
                            <Contact />
                        </Route>
                        <Route path="/product_categories">
                            <Products />
                        </Route>
                        <Route path="/product_list">
                            <ProductDetails />
                        </Route>
                        <Route path="/product_details">
                            <SingleProduct />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>

                    </Switch>
                    <Footer />
                </Grid>
            </div>
        </Router>
    );
}
