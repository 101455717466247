import { Grid, Box } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle'
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { useLocation, Link } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import cogoToast from "cogo-toast";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider, makeStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';



let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        textAlign: 'justify',
        padding: '3%'
    },
    img: {
        width: '20%',
        padding: '7%',
    },
    textStyle: {
        textAlign: "justify",
        padding: '3%',
    },
    tableHeader: {
        backgroundColor: '#1a237e',
    },
    tableCell: {
        color: 'white',
        fontSize: 'calc(0.7vw + 0.9vh)',
        // borderLeft: '1px solid #1a237e'
    },
    modalButton: {
        border: '2px solid #1a237e',
        color: '#1a237e',
        fontSize: 'calc(0.5vw + 0.7vh)',
        // marginRight: 'calc(0.45vw + 0.3vh)',
        paddingLeft: 'calc(1vw + 1vh)',
        paddingRight: 'calc(1vw + 1vh)',
        borderRadius: '25px',
        fontWeight: 600,
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#1a237e',
            color: 'white'
        },
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    media: {
        // height: '100%',
        padding: 'calc(0.5vw + 0.5vh)' // 16:9
    },
    card: {
        // position: 'relative',
        // padding: 'calc(0.5vw + 0.5vh)', // 16:9
        margin: 'calc(1vw + 1vh)',
        minWidth : 'calc(90vw)',
        cursor: 'pointer',
        "&:hover, &:focus": {
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
        },
    },
    // table: {
    //     maxWidth: 650,
    // },
    overlay: {
        position: 'absolute',
        top: '0%',
        // left: '20px',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.2)',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(154,221,251,0.6)'
        },
    },
    productName: {
        // position: 'absolute',
        // top: '45%',
        // padding: 'calc(0.5vw + 0.5vh)', // 16:9
        fontSize: 'calc(0.6vw + 0.7vh)',
        width: '100%'
        // backgroundColor: '#1a237e', color: 'white', paddingTop: '2vw', paddingBottom: '2vw'
    },
    productButton: {
        // position: 'absolute',
        // top: '45%',
        padding: 'calc(0.5vw + 0.5vh)', // 16:9
        width: '100%',
    }
}));


export default function Pricing(props) {
    const classes = useStyles();
    const [rows, setRows] = useState();
    const [loading, isLoading] = useState(false);
    let locations = useLocation();
    const [listId, setlistId] = useState((locations.hash).substring(1));

    useEffect(() => {
        axios({
            url: `${process.env.REACT_APP_BASE_API}/Api/product_list/` + listId,
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
        })
            .then(res => {
                if (res.data.status === "success" || res.data.status === true) {
                    setRows(res.data.data)
                    isLoading(true)
                }
            })
            .catch(error => {
                // console.log("in error", error.response.data.password);
                cogoToast.error(error.response.data.message)
            })
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'NAME', width: 170 },
        { field: 'description', headerName: 'DESCRIPTION', width: 200 },
        { field: 'age', headerName: 'AGE', width: 110 },

    ];

    // const rows = [
    //     { id: 1, name: 'Gourav', age: 12 },
    //     { id: 2, name: 'Geek', age: 43 },
    //     { id: 3, name: 'Pranav', age: 41 },
    // ];

    return (
        <Box className={classes.paperContainer}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                                {loading == true ? <>

                <Grid xs={12}>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <MainTitle paddingTop='calc(3vw + 3vh)' color='#1a237e' value='Product List' />
                    </ScrollAnimation>

                </Grid>
                {rows ? rows.map(o => {
                    return (
                        <>
                            <Link
                                to={{
                                    pathname: "/product_details",
                                    id: o.id,
                                    hash: "#" + o.id,
                                }}>
                                <Grid
                                    // xs={12}
                                    // md = {12}
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    className={classes.card}
                                >

                                    <Grid xs={5} sm={5} md={3} style={{ margin: '1vw + 1vh' }}>
                                        <center>
                                            {/* <ScrollAnimation animateIn='fadeInUp'> */}
                                                <img src={o.image_path} style={{ width: 'calc(10vw + 7vh)', margin: '1vw' }} />
                                            {/* </ScrollAnimation> */}
                                        </center>
                                    </Grid>
                                    <Grid xs={5} sm={5} md={7}>
                                        <div style={{ color: '#1a237e', fontSize: 'calc(1vw + 1vh)', fontWeight: 600 }}>
                                            <p>{o.name}</p>
                                        </div>
                                        <br/>
                                        {o.description != null ? <div className={classes.productName}>
                                            <p>Description : {o.description.contents}</p>
                                            <p>Weight : {o.description.weight}</p>
                                            <p>Shelf-Life : {o.description.shelfLife}</p>
                                            <p>Instructions : {o.description.instruction}</p>
                                        </div> : 
                                        // <div className={classes.productName}>
                                        // <p>Contents : hhhhhhhhhhhhttttttttttttttttiiiiiiiiiiieeeeeeeeeeeeeeeeeeeeeeeee </p>
                                        // <p>Weight : </p>
                                        // <p>Shelf-Life : </p>
                                        // <p>Heating-Instructions : </p>
                                    // </div>
                                    ""
                                        }
                                    </Grid>
                                    <Grid xs={5} sm={5} md={2} style={{padding: '1vw', paddingTop : '5vh'}} >
                                        <div>
                                            <Link
                                                to={{
                                                    pathname: "/enquire"
                                                }}>
                                                <Button className={classes.modalButton}> Enquire Now </Button>
                                            </Link>
                                            <Button style={{ backgroundColor: '#1a237e', color: 'white', marginTop: 'calc(0.5vw + 0.5vh)' }} className={classes.modalButton}> View Product </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Link>
                        </>
                    )
                })
                    : ""}
                     </>
                        : 
                        <CircularProgress style={{ color: "#1a237e", width: 'calc(3vw + 2.5vh)', height: 'calc(3vw + 2.5vh)', marginTop: '40vh' }} /> 
                }

                {/* <Grid xs={10} sm={8} md={7} >
                    <center>
                        <br />
                        <TableContainer >
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableHeader}>
                                        <TableCell className={classes.tableCell}>Product Name</TableCell>
                                        <TableCell className={classes.tableCell} style={{ width: '25%' }} align="right">Ingredients</TableCell>
                                        <TableCell className={classes.tableCell} align="right">Weight</TableCell>
                                        <TableCell className={classes.tableCell} align="right">Shelf Life</TableCell>
                                        <TableCell className={classes.tableCell} align="right">Enquire</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows ? rows.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {row.description ? <TableCell align="right">{row.description.contents}</TableCell> : ''}
                                            {row.description ? <TableCell align="right">{row.description.weight}</TableCell> : ''}
                                            {row.description ? <TableCell align="right">{row.description.shelfLife}</TableCell> : ''}
                                            <TableCell align="right">
                                                <Button className={classes.button}> Enquire Now </Button>
                                            </TableCell>
                                        </TableRow>
                                    )) : ''}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </center>
                </Grid> */}
                {/* {rows ?
                    <Grid xs={10} sm={8} md={6} >
                        <center>
                            <div style={{ height: 600, width: '80%' }}>
                                <DataGrid rows={rows}
                                    columns={columns}
                                    pageSize={3}
                                    filterModel={{
                                        items: [
                                            {
                                                columnField: '',
                                                operatorValue: '',
                                                value: ''
                                            },
                                        ],
                                    }}
                                />
                            </div>
                        </center>
                    </Grid >
                    : ""} */}

            </Grid >
        </Box >
    );
}
