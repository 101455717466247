import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle'
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import ScrollAnimation from 'react-animate-on-scroll';
import MyButton from './StyledComponents/MyButton'
import { FcDocument } from "react-icons/fc";
import { FaTruckMoving, FaHandshake, FaRegThumbsUp, FaSnowflake } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { useTheme } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        textAlign: 'justify',
        behavior: 'smooth'
    },
    // colorOverlay: {
    //     background: 'rgba(0, 132, 176, 0.4)'
    // },
    img: {
        width: '20%',
        padding: '7%',
    },
    textStyle: {
        textAlign: "justify",
        padding: '3%',
    },
    cards: {
        textAlign: "center",
        alignItems: 'center',
        padding: '2vw',
        textAlign: "center",
        alignItems: 'center',
        marginTop: '-25vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
    },
    root: {
        borderRadius: 12,
        minWidth: 256,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#fdfdfa'
        }
    },
    header: {
        textAlign: 'center',
        spacing: 10,
        fontWeight: '600'
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#1a237e',
        color: 'white'
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    divider: {
        marginTop: 'calc(1vw + 1vh)',
        marginBottom: 'calc(1vw + 1vh)'
    },
    colorOverlay: {
        height: '50vh',
        background: 'rgba(253,158,0,0.7)',
        [theme.breakpoints.down('sm')]: {
            height: '20vh'
        },
    },
}));

export default function Services(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
                padding: '5vh', behavior: 'smooth', backgroundColor: '#1a237e'
            }}
        >
            {/* <Grid xs={12}>
                <MainTitle color='white' value='Why choose us ?' />
            </Grid> */}
            <Grid xs={12} md={3}>
                <ScrollAnimation animateIn='fadeInUp'>

                    <Card style={{ margin: 'calc(2vw + 1vh)', padding: 'calc(1vw + 1vh)', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px' }} elevation={20}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid xs={2}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)' }}>183</h3>
                            </Grid>
                            <Grid xs={9}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)', color: '#29b6f6' }}>COUNTRIES</h3>
                                {/* <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p> </span> */}
                            </Grid>
                        </Grid>
                    </Card>
                </ScrollAnimation>
            </Grid>
            <Grid xs={12} md={3}>
                <ScrollAnimation animateIn='fadeInUp'>

                    <Card style={{ margin: 'calc(2vw + 1vh)', padding: 'calc(1vw + 1vh)', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px' }} elevation={20}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid xs={2}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)' }}>100+</h3>
                            </Grid>
                            <Grid xs={9}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)', color: '#29b6f6' }}>CLIENTS</h3>
                                {/* <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p> </span> */}
                            </Grid>
                        </Grid>
                    </Card>
                </ScrollAnimation>
            </Grid>
            <Grid xs={12} md={3}>
                <ScrollAnimation animateIn='fadeInUp'>

                    <Card style={{ margin: 'calc(2vw + 1vh)', padding: 'calc(1vw + 1vh)', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px' }} elevation={20}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid xs={2}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)' }}>46</h3>
                            </Grid>
                            <Grid xs={10}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)', color: '#29b6f6' }}>YEARS ACTIVE</h3>
                                {/* <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p> </span> */}
                            </Grid>
                        </Grid>
                    </Card>
                </ScrollAnimation>
            </Grid>
            <Grid xs={12} md={3}>
                <ScrollAnimation animateIn='fadeInUp'>

                    <Card style={{ margin: 'calc(2vw + 1vh)', padding: 'calc(1vw + 1vh)', borderTopRightRadius: '30px', borderBottomLeftRadius: '30px' }} elevation={20}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid xs={2}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)' }}>300+</h3>
                            </Grid>
                            <Grid xs={9}>
                                <h3 style={{ fontSize: 'calc(1vw + 1vh)', color: '#29b6f6' }}>PRODUCTS</h3>
                                {/* <span><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p> </span> */}
                            </Grid>
                        </Grid>
                    </Card>
                </ScrollAnimation>
            </Grid>
        </Grid>
    );
}