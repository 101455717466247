import { createMuiTheme, colors } from '@material-ui/core';
import { CallReceived } from '@material-ui/icons';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    // background: {
    //   // default: '#F4F6F8',
    //   paper: '#ffffff'
    // },
    // primary: {
    //   contrastText: '#ffffff',
    //   main: '#5664d2'
    // },
    text: {
      textAlign: 'center',
      primary: '#000000',
      secondary: '#6b778c'
    },
    margin: {
      card: 'calc(1vw + 1vh)'
    }
  },
  shadows,
  typography
});

export default theme;
