import { Grid, Box } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import MainTitle from './StyledComponents/MainTitle'
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownCircle';
import SignupModal from './modals/SignUpModal';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        // marginTop: '-20vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    img: {
        width: '20%',
        padding: '7%',
    },

    titleStyle: {
        fontWeight: 600,
        color: '#1a237e',
        cursor: 'pointer',
        fontFamily: 'Comfortaa',
        paddingTop: '4%',
        fontSize: 'calc(1vw + 1.2vh)',
    },
    textStyle: {
        textAlign: "justify",
        color: '#959ca0',
        cursor: 'pointer',
        lineHeight: 'calc(1vw + 1vh)',
        textAlign: 'center',
        fontSize: 'calc(0.5vw + 1vh)',
        padding: '5%',
        [theme.breakpoints.down('sm')]: {
            lineHeight: 'calc(1vw + 1vh)',
        },
    },

    button: {
        margin: theme.spacing(1),
        backgroundColor: '#1a237e',
        color: 'white'
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    media: {
        height: '100%',
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: 'calc(0.7vw + 0.5vh)',
        height: 'calc(7.5vw + 13vh)',
        borderRadius: '20px',
        backgroundColor: 'white',
        "&:hover, &:focus": {
            backgroundColor: '#f9f9f9',
            // transform: 'scale(1.1)',
        }
    },
    overlay: {
        position: 'absolute',
        top: '0%',
        // left: '20px',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.2)',
        "&:hover, &:focus": {
            backgroundColor: 'rgba(154,221,251,0.6)'
        },
    },
    downArrow: {
        // padding: 'calc(0.5vw + 0.5vh)',
        margin: 'calc(0.5vw + 0.5vh)',
        // border: '1px solid #959ca0',
        // borderRadius: '50%'
    },
    productName: {
        position: 'absolute',
        top: '45%',
        width: '100%',
    },
    modalButton: {
        border: '2px solid #1a237e',
        color: '#1a237e',
        fontSize: 'calc(0.5vw + 0.7vh)',
        // marginRight: 'calc(0.45vw + 0.3vh)',
        paddingLeft: 'calc(1vw + 1vh)',
        paddingRight: 'calc(1vw + 1vh)',
        borderRadius: '25px',
        fontWeight: 600,
        textTransform: 'none',
        "&:hover, &:focus": {
            backgroundColor: '#1a237e',
            color: 'white'
        },
    }
}));

export default function Pricing(props) {
    const classes = useStyles();
    return (
        <Box className={classes.paperContainer}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid xs={8} sm={6}>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <Card className={classes.card} elevation={16}>
                            {/* <Grid style={{ backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/3/3c/Black_triangle.svg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}> */}
                            <div style={{ borderBottom: '1px solid #e8e9ea', width: '100%', paddingBottom: 'calc(0.4vw + 0.4vh)' }}>
                                <h2 className={classes.titleStyle}>IMPORT</h2>
                            </div>
                            <Typography className={classes.textStyle}>
                                Buyers all over the globe can place orders for any products. We accept customized requests.
                            </Typography>
                            <a href="/enquire">
                                <Button size="small" className={classes.modalButton}> Enquire </Button>
                            </a>
                            {/* </Grid> */}
                        </Card>
                    </ScrollAnimation>
                </Grid>
                <Grid xs={8} sm={6}>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <Card className={classes.card} elevation={16}>
                            {/* <Grid style={{ backgroundImage: `url("https://www.pinclipart.com/picdir/middle/97-979187_triangle-clipart-svg-bleach-laundry-symbol-png-download.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}> */}
                            <div style={{ borderBottom: '1px solid #e8e9ea', width: '100%', paddingBottom: 'calc(0.4vw + 0.4vh)' }}>
                                <h2 className={classes.titleStyle}>EXPORT</h2>
                            </div>
                            <Typography className={classes.textStyle}>
                                Manufacturers/Suppliers wanting to export their products to any country can register with us.
                            </Typography>
                            <a href="/register">
                                <Button size="small" className={classes.modalButton}> Register </Button>
                            </a>
                            {/* </Grid> */}
                        </Card>
                    </ScrollAnimation>
                </Grid>
                <Grid xs={8} sm={6}>
                    <div class="floating">
                        <a href="home#product">
                             <ArrowDropDownIcon style={{ fontSize: 'calc(2vw + 2vh)', color: '#1a237e' }} />
                              </a>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
