import { Container, Grid, Box, Typography } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, withStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import React from 'react';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const styles = {
    paperContainer: {
        height: '100vh',
        textAlign: 'justify'
    },
    colorOverlay: {
        background: 'linear-gradient(to bottom right, rgba(253,95,0,0.7), rgba(253,158,0,0.7))',
    },
    p: {
        paddingTop: '2%',
        textAlign: "justify"
    },
    titleStyle: {
        lineHeight: '120%',
        fontWeight: 500,
        fontSize: 'calc(0.7vw + 2vh)'
    },
    textStyle: {
        color: '#1a237e',
        lineHeight: '140%',
        textAlign: "center",
        fontSize: 'calc(0.5vw + 1.7vh)'

    },
};

function Attribution(props) {
    return (
        <Box className={props.classes.colorOverlay}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ padding: '3vw', height: '100vh' }}
            >
                <Grid xs={12}>
                    <Container >
                        <ScrollAnimation animateIn='slideInRight'>

                            <Typography style={styles.titleStyle} >
                                Attributions
                            </Typography>

                            <br />
                            <Typography style={styles.textStyle}>
                                <a href='https://www.freepik.com/photos/business'>Business photo created by pressfoto - www.freepik.com</a>
                            </Typography>
                        </ScrollAnimation>

                    </Container>
                </Grid>
            </Grid>

        </Box>
    );
}

export default withStyles(styles)(Attribution);